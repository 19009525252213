<template>
  <div id="home"> </div>
</template>

<script>
  import Papa from 'papaparse';
  export default {
    components: {},
    name: 'Home',
    created() {
      // this.$axios.get('/LE_Store.csv').then((res) => {
      //   console.log('res:', res);
      //   const data = Papa.unparse(res);
      //   console.log('data:', data);
      // });

      const arr = [];
      Papa.parse('/LE_Store.csv', {
        download: true,
        step: (row) => {
          console.log('Row:', row.data);
          arr.push(row.data);
        },
        complete: () => {
          console.log('All done!');
          console.log('arr:', arr);
        },
      });
    },
  };
  //   const fs = require('fs');

  //   try {
  //     const data = fs.readFileSync('/LE_Store.csv', 'utf8');
  //     console.log(data);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // },
  // };
</script>

<style lang="scss" scoped></style>
